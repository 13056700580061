













import { AppModule } from '@/store/modules/app'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'error',
  components: {}
})
export default class extends Vue {
  created() {}
}
