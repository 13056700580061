




























































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, getQrCode } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
@Component({
  name: 'qrIndex',
  components: {}
})
export default class extends Vue {
  get isMobile() {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  public clipboardSuccess = clipboardSuccess
  public loading: boolean = true
  public isBindBank: boolean = true
  public expireTime: string = ''
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public config: any = {}
  public configdata: any = {}
  public time: any = '00:00'
  public timer: any = null
  public poll: any = null
  public currencyType: any = currencyType
  public retryCount: number = 3
  public videoUrl: any = ''

  // 视频演示播放
  public videoShow: boolean = false
  public videoPlay() {
    this.videoShow = true
  }

  public closePlay() {
    this.videoShow = false
  }

  // 获取二维码
  public qrCode = {
    url: ''
  }
  public getQrcode = async () => {
    await getQrCode().then((res: any) => {
      this.qrCode.url = `data:image/png;base64,${res.qrcode}`
    })
  }

  public strWarning: string = ''
  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        this.videoUrl = this.orderInfo.video_url
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.strWarning = this.config.qrcode_warning.replace(/\n/g, '<br>')
          this.configdata = res.extra_param
          AppModule.SetChatInfo(res.extra_param.config)
          // AppModule.SetChatInfo(res.extra_param)
          if (
            this.isMobile &&
            this.configdata.use_spanish &&
            this.configdata.use_spanish === true
          ) {
            this.$alert(
              `
      <div style="text-align: left;">
      <div>
      Pista importante:
      </div>
      <p>
      1. Se ha generado el pedido, ahora puede copiar la dirección de la billetera o tomar una captura de pantalla para guardar el código QR y escanear el código QR para transferir dinero.
      </p>
      <p>
      2. El código QR y la dirección de la billetera generados por cada transacción solo se pueden usar una vez, no realice pagos repetidos, de lo contrario, no se acreditará la cuenta.
      </p>
      <p>
      3. Solo se admite el tipo de dirección de billetera TRC20. No use el tipo de protocolo de billetera incorrecto, de lo contrario, no se acreditará la cuenta.
      </p>
      <p>
      4. El pedido es válido durante 1 hora. Después de la transferencia y el pago exitosos dentro del período de validez, el pedido en esta página se confirmará automáticamente
      </p>
      </div>`,
              ``,
              {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'Confirmar para cerrar',
                center: true,
                showClose: false
              }
            )
          }
        }
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                clearInterval(this.timer)
                clearInterval(this.poll)
                router.replace({ name: 'vietnamErr' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'vietnamErr' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'vietnamScccess' })
        }
        this.loading = false
      })
    } catch (e) {
      let err: any = e
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'vietnamErr' })
            })
        }
      }
    }
  }
  public jumpUrl() {
    let url: any = sessionStorage.getItem('return_url')
    console.log(url)

    window.open(url)
  }

  mounted() {
    this.getCounter()
    this.getQrcode()
  }

  destroyed() {
    if (this.poll || this.timer) {
      clearInterval(this.poll)
      clearInterval(this.timer)
    }
  }
}
