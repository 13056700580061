
















































































































































import { Component, Vue } from 'vue-property-decorator'
import router from '@/router'
import { getCounter, getQrCode } from '@/api/index'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import { AppModule } from '@/store/modules/app'
import { clipboardSuccess } from '@/utils/clipboard'
import { currencyType } from '@/utils/constant'
import { dateToSeconds } from '@/utils/dateTime'
@Component({
  name: 'qrIndex',
  components: {}
})
export default class extends Vue {
  get isMobile() {
    return window.screen.availWidth <= 475 && window.screen.availWidth >= 310
  }
  private clipboardSuccess = clipboardSuccess
  private loading: boolean = true
  private isBindBank: boolean = true
  private qrcode: string = ''
  private expireTime: string = ''
  private orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  private config: any = {}
  private configdata: any = {}
  private time: any = '00:00'
  private timer: any = null
  private poll: any = null
  private currencyType: any = currencyType
  private retryCount: number = 3
  private trading: string = ''

  // 视频演示播放
  private videoShow: boolean = false
  private videoPlay() {
    this.videoShow = true
  }
  private closePlay() {
    this.videoShow = false
  }

  // 获取二维码
  private qrCode = {
    url: ''
  }
  private getQrcode = async () => {
    await getQrCode().then((res: any) => {
      this.qrCode.url = `data:image/png;base64,${res.qrcode}`
    })
  }

  private async getCounter() {
    try {
      await getCounter().then((res: any) => {
        this.orderInfo = res
        if (!AppModule.chatInfo) {
          this.config = res.extra_param.config
          this.configdata = res.extra_param
          this.trading = res.extra_param.qrcode_value
          AppModule.SetChatInfo(res.extra_param.config)
          // AppModule.SetChatInfo(res.extra_param)
        }
        // 取最小时间
        const time = res.extra_param.expire_time
          ? res.extra_param.expire_time > res.expire_time
            ? res.expire_time
            : res.extra_param.expire_time
          : res.expire_time
        // 根据语言获取时区
        const timezone =
          this.$route.query['language'] === 'vi' ||
          this.$route.query['language'] === 'th' ||
          this.$route.query['language'] === 'id' ||
          this.$route.query['language'] === 'en'
            ? 'Asia/Bangkok'
            : ''
        this.expireTime = timezone
          ? momentTimezone
              .tz(time * 1000, timezone)
              .format('YYYY-MM-DD HH:mm:ss')
          : moment(time * 1000).format('YYYY-MM-DD HH:mm:ss')
        if (!res.is_expired && res.status === 0) {
          // 订单未过期 且 未支付
          if (!this.poll) {
            // 轮询未开始，则开始轮询
            this.poll = setInterval(() => {
              this.getCounter()
            }, 5000)
            const diffTime = res.current_time * 1000 - Date.now()
            this.timer = window.setInterval(async () => {
              let start = momentTimezone.tz(Date.now() + diffTime, timezone) //获取开始时间
              let end = momentTimezone.tz(time * 1000, timezone) //结束时间
              let diff = end.diff(start) //时间差
              let minutes: any = moment.duration(diff).minutes()
              minutes = minutes < 10 ? '0' + minutes : minutes
              let seconds: any = moment.duration(diff).seconds()
              seconds = seconds < 10 ? '0' + seconds : seconds
              if (diff > 0) {
                this.time = minutes + ':' + seconds
              } else {
                clearInterval(this.timer)
                clearInterval(this.poll)
                router.replace({ name: 'error' })
              }
            }, 1000)
          }
        } else if (res.is_expired) {
          // 订单已过期
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 已支付
          if (this.poll) {
            // 轮询开启则关闭
            clearInterval(this.poll)
            clearInterval(this.timer)
          }
          router.replace({ name: 'success' })
        }
        this.loading = false
      })
      if (
        this.orderInfo.status === 0 &&
        this.orderInfo.extra_param.code === 201
      ) {
        this.$confirm(this.orderInfo.extra_param.message, 'The Request Timeout')
          .then(() => {
            this.retryCount = 3
            this.getCounter()
          })
          .catch(() => {
            router.replace({ name: 'error' })
          })
        if (this.poll) {
          // 轮询开启则关闭
          clearInterval(this.poll)
          clearInterval(this.timer)
        }
      }
    } catch (e) {
      if (!this.poll) {
        if (this.retryCount-- > 0) {
          this.getCounter()
        } else {
          this.$confirm(`Do you want to try again?`, 'The Request Timeout')
            .then(() => {
              this.retryCount = 3
              this.getCounter()
            })
            .catch(() => {
              router.replace({ name: 'error' })
            })
        }
      }
      throw new Error(`${this.$route.path}Counter接口出错`)
    }
  }

  mounted() {
    this.getQrcode()
    this.getCounter()
  }

  destroyed() {
    if (this.poll || this.timer) {
      clearInterval(this.poll)
      clearInterval(this.timer)
    }
  }
}
